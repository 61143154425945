import React, { useState, useCallback, useMemo } from "react";
import loadable from "@loadable/component";
import "./DropDownListItem.scss";
import { ReactSVG } from "react-svg";
import classNames from "classnames";
import arrowDown from "@images/common/ic-arrow-down.svg";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { MOBILE_MAX_WIDTH } from "@constants";
import { MAIN_BUTTON } from "../../../../../constants";
import arrowRight from "@images/common/ic-arrow-right.svg"

const CommonList = loadable(() => import("@common/CommonList/CommonList"));
const Button = loadable(() => import("@common/Button/Button"))

const DropDownListItem = ({
    iconPath,
    title,
    path,
    description,
    descriptionItems,
    itemsMargin,
    changeTitleOnClick,
}) => {
    const { width } = useWindowDimensions();

    const isMobile = useMemo(
        () => width <= MOBILE_MAX_WIDTH,
        [MOBILE_MAX_WIDTH, width]
    );

    const [showMore, setShowMore] = useState(false);

    const arrowBtnClassName = classNames(
        "product-development-item__arrow-btn",
        {
            "product-development-item__arrow-btn-rotate": !!showMore,
        }
    );
    const handleClick = useCallback(() => {
        setShowMore(!showMore);
    }, [setShowMore, showMore]);

    const blurClassName = classNames("product-development-item__icon-blur", {
        "product-development-item__icon-blur-active": !!showMore && !isMobile,
    });

    const infoContainerClassName = classNames(
        "product-development-item__info-container",
        {
            "product-development-item__info-container-show": !!showMore,
        }
    );

    const headingClassName = classNames("product-development-item__heading", {
        "product-development-item__heading-colored":
            !!changeTitleOnClick && !!showMore,
    });

    const rowGap = useMemo(() => (isMobile ? "16px" : "24px"), [isMobile]);

    const validateDescription = useCallback((description) => {
        const newDescription = []

        if(Array.isArray(description?.props?.children)) {
            description.props.children.map((el, index) => {
                if(!el?.props?.children) {
                    newDescription.push(<br />)
                }
                else {
                    newDescription.push(
                        <>
                            {el?.props?.children}
                        </>
                    )
                }
            })
            return newDescription
        }
        
        return description
    }, [description])

    return (
        <div
            className="product-development-item"
            onClick={handleClick}
            style={
                itemsMargin && {
                    marginBottom: itemsMargin,
                }
            }
        >
            <div className="product-development-item__icon-container">
                <div className={blurClassName} />
                <ReactSVG
                    src={iconPath}
                    alt="icon"
                    className="product-development-item__icon"
                />
            </div>
            <div className="product-development-item__heading-container">
                <h2 className={headingClassName}>{title}</h2>
                <div className={arrowBtnClassName}>
                    <ReactSVG src={arrowDown} alt="arrow" />
                </div>
            </div>
            <div className={infoContainerClassName}>
                <p className="product-development-item__description">
                    {validateDescription(description)}
                    <div className="product-development-item__description-button">
                        {path &&  (
                            <Button
                                text="Learn more"
                                type={MAIN_BUTTON}
                                bannerButtonIconPath={arrowRight}
                                href={path}
                            />
                        )}
                    </div>
                </p>
                <CommonList rowGap={rowGap} listItems={descriptionItems} />
            </div>
        </div>
    );
};

export default DropDownListItem;
